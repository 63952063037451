<template>
    <div class="main-banner">
        <!-- 当前定位 -->
        <div class="main-banner-position">
            {{pagePosition}}
        </div>
        <!-- 标题 -->
        <div class="main-banner-title">
            {{pageTitle}}
        </div>
        <!-- 搜索框 -->
        <div class="main-banner-search">
            <!-- 搜索框 -->
            <slot name="search"></slot>
            <!-- 快捷选项 -->
            <slot name="searchFast"></slot>
        </div>
        <!-- 路由切换 -->
        <!-- <router-link 
            :to="routerPath"
            class="main-banner-router">
            {{routerName}}
            <i class="el-icon-d-arrow-right"></i>
        </router-link> -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                select_fast: "",
            }
        },
        props: {
            pagePosition: String,
            pageTitle: String,
            routerName: String,
            routerPath: String
        },
    }
</script>

<style scoped>
.main-banner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    padding: 80px 0;
    background-color: rgb(34, 75, 181);
}
/* position */
.main-banner-position{
    padding: 8px 40px;
    background-color: var(--color);
    border-radius: 30px;
    letter-spacing: 10px;
    font-size: 30px;
    font-weight: lighter;
    color: #fff;
}
/* title */
.main-banner-title{
    letter-spacing: 10px;
    font-size: 30px;
    font-weight: lighter;
    color: #fff;
}
/* search */
.main-banner-search{
    width: 700px;
}
/* router */
.main-banner-router{
    margin-top: 50px;
    letter-spacing: 6px;
    font-size: 26px;
    font-weight: lighter;
    color: #fff;
}
.main-banner-router > i{
    font-size: 26px;
}
</style>